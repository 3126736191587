import { STATIC_URL, PROD_STATIC_PATH, URL_SITE, Pathname } from 'constants/data'

const imgPath = {
  logo_medp: `${STATIC_URL}/footer/logo_medp_white.svg`,
  logo_onpoint: `${STATIC_URL}/footer/logo_onpoint.svg`,
  phone: `${STATIC_URL}/footer/icon_phone.svg`,
  mail: `${STATIC_URL}/footer/icon_mail.svg`,
  logo_youtube: `${STATIC_URL}/footer/icon_yt.svg`,
  icon_vk: `${STATIC_URL}/footer/icon_vk.svg`,
  icon_telegr: `${STATIC_URL}/footer/icon_tlg.svg`,
}

const partnerLinks = [
  {
    href: 'https://www.youtube.com/channel/UC6m9HbtD9DAX8UtK6fg9TIg',
    logo: imgPath.logo_youtube,
    text: <>Официальный&nbsp;YouTube канал&nbsp;MEDPOINT</>,
    className: '',
    alt: 'ссылка на YouTube канал',
  },
  {
    href: 'https://vk.com/club206931593',
    logo: imgPath.icon_vk,
    text: <>Официальная&nbsp;группа Вконтакте&nbsp;MEDPOINT</>,
    className: '',
    alt: 'ссылка на группу в Вконтакте',
  },
  {
    href: Pathname.TELEGRAM,
    logo: imgPath.icon_telegr,
    text: <>Официальный&nbsp;Telegram канал&nbsp;MEDPOINT</>,
    className: '',
    alt: 'ссылка на Telegram канал',
  },
]

const legalInfo = [
  {
    href: `${PROD_STATIC_PATH}/aboutOrganization/information_license.pdf`,
    text: 'Лицензия на образовательные услуги',
  },
  {
    href: `${PROD_STATIC_PATH}/bonus_terms.pdf`,
    text: 'Правила бонусной программы',
  },
  {
    href: `${PROD_STATIC_PATH}/terms.pdf`,
    text: 'Пользовательское соглашение',
  },
  {
    href: `${PROD_STATIC_PATH}/privacy.pdf`,
    text: 'Политика в отношении обработки персональных данных',
  },

  {
    href: `${PROD_STATIC_PATH}/agreement.pdf`,
    text: 'Согласие на обработку персональных данных',
  },
  {
    href: `${PROD_STATIC_PATH}/oferta_access_services.pdf`,
    text: 'Оферта на услуги доступа',
  },
  {
    href: `${PROD_STATIC_PATH}/oferta_educational_services.pdf`,
    text: 'Оферта на образовательные услуги',
  },
  {
    href: `${URL_SITE}/payment`,
    text: 'Оплата',
  },
  {
    href: `${URL_SITE}/about-organization`,
    text: 'Сведения об образовательной организации',
  },
]

const qualification = [
  {
    href: `${URL_SITE}/program`,
    text: 'Программы',
  },
  {
    href: `${URL_SITE}/nmo`,
    text: 'Баллы НМО: новые правила аккредитации',
  },
  {
    href: `${URL_SITE}/nmo/accreditation`,
    text: 'Налоговый вычет за обучение',
  },
  {
    href: `${URL_SITE}/nmo/qualification`,
    text: 'Способы оплаты курсов повышения квалификации для врачей',
  },
]

const aboutUs = [
  {
    href: `${URL_SITE}/about`,
    text: 'О нас',
  },
  {
    href: `${URL_SITE}/partners`,
    text: 'Партнеры',
  },
  {
    href: `${URL_SITE}/lecture`,
    text: 'Наши лекторы',
  },
  {
    href: `${PROD_STATIC_PATH}/KP_School_RAGG.pdf`,
    text: 'Для спонсоров',
  },
  {
    href: `${URL_SITE}/map-site`,
    text: 'Карта сайта',
  },
]

export { legalInfo, qualification, aboutUs, partnerLinks, imgPath }
